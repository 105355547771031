import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEmbeddedConfig } from 'store/embeddingConfSlice';

export const EmbeddingContext = React.createContext<IEmbeddingContext>({
    wall: {},
    actionsBar: {},
    iNotification: {},
    bookmark: {},
    segment: {},
    chart: {},
});

export interface IEmbeddingContext {
    wall: { hide?: string };
    actionsBar: { hide?: string };
    bookmark: { hide?: string };
    segment: { hide?: string };
    iNotification: { hide?: string };
    chart: { chart?: string };
}

const pref = '_ec_';

export function useSetupEmbeddingContext() {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const storeState = useSelector((state: any) => state.embeddingConf.data) as IEmbeddingContext;

    const iterate = (o: any) => {
        for (const [key, value] of Object.entries(o)) {
            if (typeof value == 'object') {
                iterate(value);
            } else {
                const p = `${pref}${o[key]}`;
                const pv = urlParams.get(p);
                o[key] = pv == null ? undefined : pv;
            }
        }
    };

    useEffect(() => {
        const paramsMap: IEmbeddingContext = {
            wall: { hide: 'wall' },
            actionsBar: { hide: 'actionsBar' },
            iNotification: { hide: 'iNotification' },
            bookmark: { hide: 'bookmark' },
            segment: { hide: 'segment' },
            chart: { chart: 'chart' },
        };
        iterate(paramsMap);
        if (window.location.search.includes(pref)) {
            dispatch(
                setEmbeddedConfig({
                    newState: paramsMap,
                })
            );
        }
    }, []);

    return storeState;
}

export function useEmbeddingContextVisible(element: keyof IEmbeddingContext) {
    const embeddingContext = useContext(EmbeddingContext);
    //@ts-ignore
    return !(embeddingContext?.[element]?.hide == '1');
}
