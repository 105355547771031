import React, { lazy, useEffect, useState } from 'react';
import { Box, Button, alpha, Stack, Container } from '@mui/material';
import useBundleTranslation from 'i18n';
import { DenyPageAPI } from 'api/api';
import styles from './DenyPage.styles';
import { Link, useParams } from 'react-router-dom';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import denyIcon from './deny-ico.svg';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from 'store/pageTitleSlice';
import { useConfigConstantValue } from 'hooks/useConfigConstant';
import { AccessRequestElementInfo, homePage } from 'api/home-page';

const IconMi = lazy(() => import('components/common/icon/IconMi'));

interface denyPageData {
    info?: any;
}
const DenyPage = (props: any) => {
    const { t } = useBundleTranslation(['app/auth/deny']);
    const urlParams = useParams();
    const dispatch = useDispatch();
    const [type, setType] = useState<string>();
    const [isElement, setIsElement] = useState<boolean>(false);
    const [mainTitle, setMainTitle] = useState<string>('');
    const [isSentRequest, setIsSentRequest] = useState<boolean>(false);
    const [sentRequestMessage, setSentRequestMessage] = useState<string>('');

    const supportAdmins = useSelector((state: any) => state.auth?.userInfo?.supportAdmins) ?? [];
    const okButtonText = useConfigConstantValue('ACCESS_REQUEST_BUTTON_TEXT');
    const accessRequestUrl = useConfigConstantValue('ACCESS_REQUEST_URL');
    const accessRequestViaWebpage = useConfigConstantValue('ACCESS_REQUEST_VIA_WEBPAGE');
    let urlData: any = Object.fromEntries(new URLSearchParams(document.location.search));
    const page = urlParams?.page ? urlParams.page : ''; //todo @htmlentities(urlParams.page)
    let elementLabel = t('element.default');
    let linkText = okButtonText || t('link_text.send_access_request');
    const componentPageInfo = props?.info;

    useEffect(() => {
        let typeValue = componentPageInfo ? props.type : urlParams?.type;
        if ((undefined == typeValue || '' == typeValue) && urlData.type && urlData.type > '') typeValue = urlData.type;
        setType(typeValue ?? '');

        setIsElement(typeValue === 'permission' && urlData.entity === 'DashboardElementView');
    }, []);

    useEffect(() => {
        const newMainTitle =
            'notfound' === type || 'not_found' === type
                ? 'notfound'
                : type === 'not_have_data_dataset'
                ? 'dataset_no_data_title'
                : 'title';
        setMainTitle(newMainTitle);
        dispatch(setPageTitle(t(newMainTitle)));
    }, [type]);

    const { data: pageData, refetch } = useQuery<denyPageData, Error>(
        ['accessDenyPage'],
        () => {
            return DenyPageAPI.getData({ type: type, data: urlData });
        },
        { enabled: !componentPageInfo && type !== undefined }
    );

    const { data: accessRequestInfoData } = useQuery<AccessRequestElementInfo, Error>(
        ['element_preview_data'],
        () => {
            return homePage.getAccessRequestInfo(Number(urlData.entityId), Number(urlData.segmentId));
        },
        { enabled: isElement }
    );

    let errorInfo: any = componentPageInfo ?? pageData?.info;
    let errorText: any = {
        key: '',
        data: {},
        cssClass: '',
    };
    let url: any = {
        href: '',
        text: '',
    };

    if (!errorInfo)
        return (
            <LoadingPlaceholder
                sx={{
                    position: 'absolute',
                    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                    color: (theme) => alpha(theme.palette.text.primary, 0.4),
                }}
            />
        );

    const ErrorMsg = () => {
        if (!urlParams.error) return null;
        const errors = Array.isArray(urlParams.error) ? urlParams.error : [urlParams.error];
        return (
            <>
                {errors.map((item, index) => {
                    //todo @htmlentities // htmlspecialchars
                    return <Box key={index}>{item}</Box>;
                })}
            </>
        );
    };

    const pluralVariable = (word: string) => {
        const lastLetter = word.slice(-1);
        switch (lastLetter) {
            case 'y':
                return word.slice(0, -1) + 'ies';
            case 's':
                return word + 'es';
            default:
                return word + 's';
        }
    };

    const sendRequestLink = () => {
        if (accessRequestViaWebpage && accessRequestUrl) {
            window.open(accessRequestUrl, '_blank');
            return;
        }

        DenyPageAPI.requireTilesAccess(errorInfo.entity.elementId ?? 0, errorInfo.entity.segmentValueId ?? 0)
            .then((response) => {
                if (response.data?.status === 'OK') {
                    setIsSentRequest(true);
                    setSentRequestMessage(response.data.message);
                }
            })
            .catch(() => {});
    };

    const inIframe = (() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    })();

    if (errorInfo && errorInfo.message != '' && 'Y' == errorInfo.fullPageMessage)
        return <Box sx={styles.mainWrapper} dangerouslySetInnerHTML={{ __html: errorInfo.message }}></Box>;

    return (
        <Box sx={styles.mainWrapper}>
            <Container maxWidth="sm" component="main" disableGutters={true} sx={styles.container}>
                <Stack direction="row" justifyContent="space-between" sx={styles.dataWrapper}>
                    <Stack spacing={1}>
                        {type ? (
                            <>
                                <Box sx={styles.title}>{t(mainTitle)}</Box>
                                <Box sx={styles.message}>
                                    {errorInfo && errorInfo.message != '' && (
                                        <Box className={errorInfo.cssClass ?? undefined}>
                                            <Box
                                                component={'span'}
                                                dangerouslySetInnerHTML={{ __html: errorInfo.message }}
                                                sx={{ 'p:last-of-type': { mb: 0 }, 'p:first-of-type': { mt: 0 } }}
                                            />
                                            {url.text != '' && (
                                                <>
                                                    {' '}
                                                    <Link to={url.href}>{url.text}</Link>
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    {errorText.key != '' && (
                                        <Box className={errorText.cssClass ?? undefined}>
                                            {t(errorText.key, errorText.data)}
                                        </Box>
                                    )}
                                </Box>
                            </>
                        ) : (
                            <ErrorMsg />
                        )}

                        {supportAdmins.length > 0 && (
                            <Box>
                                <Box>{t('access_support_administrators')}</Box>
                                {supportAdmins.map((user: any) => {
                                    return (
                                        <Stack direction="row" key={user.username}>
                                            <Box>
                                                <IconMi icon={'user'} />
                                            </Box>
                                            <Box sx={{ ml: 1 }}>{user.display_name}</Box>
                                        </Stack>
                                    );
                                })}
                            </Box>
                        )}

                        {!inIframe && (
                            <Stack sx={{ pt: 1 }} direction={'row'} spacing={0.5} alignItems={'center'}>
                                <Button
                                    data-test={'return-button'}
                                    component={Link}
                                    to={'/home'}
                                    sx={{ flexShrink: 0 }}
                                >
                                    {t('return_btn.default')}
                                </Button>
                                {'permission' == type && (
                                    <>
                                        {isSentRequest ? (
                                            <Box sx={{ color: 'error.main', pl: 1, lineHeight: 1.2 }}>
                                                {sentRequestMessage}
                                            </Box>
                                        ) : (
                                            <>
                                                {' '}
                                                <Box sx={{ pl: 1.5 }}>{t('or_link_text')}</Box>
                                                <Button
                                                    data-test={'send-request-button'}
                                                    onClick={sendRequestLink}
                                                    variant="text"
                                                >
                                                    {linkText}
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </Stack>
                        )}
                    </Stack>
                    <Box sx={{ flexShrink: 0, ml: 1.5 }}>
                        {accessRequestInfoData && accessRequestInfoData.accessData?.image ? (
                            <Box component="img" src={accessRequestInfoData.accessData?.image} />
                        ) : (
                            <Box component="img" src={denyIcon} />
                        )}
                    </Box>
                </Stack>
            </Container>
        </Box>
    );
};

export default DenyPage;
