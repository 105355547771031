import { Chart as HChart, SVGElement } from 'highcharts';
import { ChartPoint } from 'components/metric/chart/index';
import { manageAlertSVG, SVG_GROUP_ALERT_ICONS } from 'components/metric/chart/draw/chartRedrawAlerts';
import { manageEventSVG, SVG_GROUP_EVENT_ICONS } from 'components/metric/chart/draw/chartRedrawEvents';
import { AssocArray } from 'tools/types';
import { ChartPointAlert } from 'components/metric/chart/series/getAlertSeries';
import { ChartPointEvent } from 'components/metric/chart/series/getEventSeries';
import { manageAnnotationSVG, SVG_GROUP_ANNOTATION_ICONS } from 'components/metric/chart/draw/chartRedrawAnnotations';
import { ChartPointAnnotation } from 'components/metric/chart/series/getAnnotationSeries';
import { ChartSVGPointEvents } from 'components/metric/chart/MetricChartWrapper';
import { Theme } from '@mui/material';

const BORDER_HEIGHT = 5;

export const SVG_GROUP_RANGES_LINES = 'dRangesLines';

export interface AdditionalToDrawType {
    points: Array<PointToDrawType<ChartPoint>>;
    ranges: Array<RangeToDrawType<ChartPoint>>;
}

export interface PointToDrawType<T extends ChartPoint> {
    type: 'alert' | 'event' | 'annotation';
    x: number;
    y: number;
    point: T;
}

export interface RangeToDrawType<T extends ChartPoint> extends PointToDrawType<T> {
    x1: number;
    leftBorder: boolean;
    rightBorder: boolean;
}

function drawRanges(
    chart: HChart,
    x0: number,
    x1: number,
    y: number,
    strokeColor: string,
    iconsGroup: SVGElement,
    leftBorder: boolean = true,
    rightBorder: boolean = true
) {
    const style = {
        'stroke-width': 1,
        transform: 'translate(0.5,0.5)',
        stroke: strokeColor,
    };

    const maxX = chart.plotLeft + chart.plotWidth;

    if (x1 < chart.plotLeft || x0 > maxX) {
        return false;
    }
    if (x1 > maxX) {
        x1 = maxX;
        rightBorder = false;
    }

    if (x0 < chart.plotLeft) {
        x0 = chart.plotLeft;
    } else if (leftBorder) {
        chart.renderer
            //@ts-ignore
            .path(['M', x0, y - BORDER_HEIGHT, 'L', x0, y + BORDER_HEIGHT])
            .attr(style)
            .add(iconsGroup);
    }

    if (x0 > chart.plotLeft + chart.plotWidth) {
        x0 = chart.plotLeft + chart.plotWidth;
    } else if (rightBorder) {
        chart.renderer
            //@ts-ignore
            .path(['M', x1, y - BORDER_HEIGHT, 'L', x1, y + BORDER_HEIGHT])
            .attr(style)
            .add(iconsGroup);
    }
    //@ts-ignore
    chart.renderer.path(['M', x0, y, 'L', x1, y]).attr(style).add(iconsGroup);

    return (x0 + x1) / 2;
}

export function drawSVG<T extends ChartPoint>(
    theme: Theme,
    chart: HChart,
    type: 'point' | 'range',
    toDraw: RangeToDrawType<T> | PointToDrawType<T>,
    svgRangesGroup: SVGElement,
    svgIconsEvents?: ChartSVGPointEvents,
    isSimulation?: boolean
) {
    const xAxis = chart.xAxis[0];
    const yAxis = chart.yAxis[0];

    if (type == 'range') {
        let strokeColor = theme.componentSettings.highstockChart.annotation;
        const range = toDraw as RangeToDrawType<ChartPoint>;

        // const plotX = (xAxis.toPixels(range.x, false) + xAxis.toPixels(range.x1, false)) / 2;
        let plotX = drawRanges(
            chart,
            chart.xAxis[0].toPixels(range.x, false),
            chart.xAxis[0].toPixels(range.x1, false),
            chart.yAxis[0].toPixels(range.y, false),
            strokeColor,
            svgRangesGroup,
            range.leftBorder,
            range.rightBorder
        );

        if (plotX == false) {
            plotX = -10000;
        }

        if (range.type == 'alert') {
            if ((range.point as ChartPointAlert)?.metadata?.workflow_status_state == 'closed') {
                strokeColor = theme.componentSettings.highstockChart.closedAlert;
            }
            manageAlertSVG(
                chart,
                range.point as ChartPointAlert,
                plotX - 10, //Half of icon,
                yAxis.toPixels(range.y, false) - 20,
                svgIconsEvents
            );
        } else if (range.type == 'event') {
            manageEventSVG(
                chart,
                range.point as ChartPointEvent,
                plotX - 13, //Half of icon,
                yAxis.toPixels(range.y, false) - 24,
                svgIconsEvents
            );
        } else if (range.type == 'annotation') {
            manageAnnotationSVG(
                chart,
                range.point as ChartPointAnnotation,
                plotX - 10, //Half of icon,
                yAxis.toPixels(range.y, false) - 24,
                svgIconsEvents
            );
        }
    } else {
        const point = toDraw as PointToDrawType<ChartPoint>;
        if (point.type == 'alert') {
            let position = xAxis.toPixels(point.x, false) - 10;
            //@ts-ignore
            if (!isSimulation && (position <= chart?.axisOffset?.[3] ?? 0)) {
                position = -1000;
            }
            manageAlertSVG(
                chart,
                point.point as ChartPointAlert,
                position,
                yAxis.toPixels(point.y, false),
                svgIconsEvents
            );
        }
    }
}

export function drawNumberOnIcon(chart: HChart, number: number, padding: number = 0, color: string = 'white') {
    // Set text position
    let xSpacing = 10 + padding;
    if (number > 9) {
        xSpacing -= 2;
    }

    return chart.renderer
        .text(String(number), xSpacing, 11)
        .css({
            color: color,
            fontSize: '10px',
        })
        .add().element;
}

let svgIconsGroupMap: AssocArray<SVGElement> = {};

export function getSVGIconsGroup(chart: HChart, className: string) {
    if (svgIconsGroupMap[className]) {
        return svgIconsGroupMap[className];
    }

    let iconsGroup: SVGElement;
    let iconsGroupByClass = document.getElementsByClassName(className);

    if (iconsGroupByClass.length == 0) {
        //@ts-ignore
        iconsGroup = chart.renderer
            .g()
            .attr('zIndex', 101)
            .addClass(className)
            .css({ cursor: 'pointer' })
            .add().element;
    } else {
        //@ts-ignore
        iconsGroup = iconsGroupByClass[0];
    }
    return (svgIconsGroupMap[className] = iconsGroup);
}

export function clearChart() {
    [SVG_GROUP_ALERT_ICONS, SVG_GROUP_EVENT_ICONS, SVG_GROUP_ANNOTATION_ICONS].forEach((className) => {
        let elements = document.getElementsByClassName(className);
        while (elements.length > 0) {
            // @ts-ignore
            elements[0].parentNode.removeChild(elements[0]);
        }
    });
    svgIconsGroupMap = {};
}

export function refreshSVGRangesGroup(chart: HChart) {
    // Remove ChartRangeAlerts lines
    const elements = document.getElementsByClassName(SVG_GROUP_RANGES_LINES);
    while (elements.length > 0) {
        // @ts-ignore
        elements[0].parentNode.removeChild(elements[0]);
    }
    return chart.renderer.g().attr('zIndex', 101).addClass(SVG_GROUP_RANGES_LINES).add();
}
