import {
    FormComponentValue,
    FormElementControlPropsType,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import React, { useEffect, useState } from 'react';
import { FormControlElementImageProps } from 'components/element-image';
import 'react-image-crop/src/ReactCrop.scss';
import useBundleTranslation from 'i18n';
import pollingService, { PoolingRequestData } from 'tools/pollingService';
import IconMi from 'components/common/icon/IconMi';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { useQuery } from '@tanstack/react-query';
import { editorAPI } from 'api/editor';

import UploadCustomImagePopup, { FileUploadResponse } from 'components/element-image/editor/UploadCustomImagePopup';
import SelectSegmentValuePopup from 'components/element-image/editor/SelectSegmentValuePopup';
import { APIResponse, YNValues } from 'tools/types';
import { processSettingsUrl } from 'components/common/form/formTools';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { useFormState } from 'react-hook-form';
import { setFormElementSharedState } from 'store/formSlice';
import { useDispatch } from 'react-redux';
import SetupImageFormWrapper from 'components/element-image/editor/form/SetupImageWrapper';
import { instance } from 'api/api';
import useHasImageToRemove from 'components/element-image/useHasImageToRemove';
import styles from './ElementImageControl.styles';

interface CollectImageRequestType {
    sv_id: number;
    generic?: YNValues;
}

export default function ElementImageControl({
    controlProps,
}: FormElementControlPropsType<FormControlElementImageProps>) {
    const [segmentValueId, setSegmentValueId] = useState<number>(controlProps.segmentValueId);
    const [imageTimeStamp, setImageTimeStamp] = useState<string>(String(controlProps.urlParams?.stamp ?? Date.now()));
    const defaultSegmentValueId = Number(controlProps.segmentValueId);

    useEffect(() => {
        // Update component Shared State
        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: { stamp: imageTimeStamp },
            })
        );
    }, [imageTimeStamp]);

    const urlParams = { ...controlProps.urlParams, stamp: imageTimeStamp };
    const fullUrlParams = { ...urlParams, segmentValueId: String(segmentValueId) };

    const pks = Object.keys(urlParams);
    const fullPks = Object.keys(fullUrlParams);

    const previewUrl = processSettingsUrl(controlProps.previewUrl, fullPks, fullUrlParams);
    const thumbnailUrl = processSettingsUrl(controlProps.thumbnailUrl, fullPks, fullUrlParams);
    const originalUrl = processSettingsUrl(controlProps.originalUrl, fullPks, fullUrlParams);
    const setupImgUrl = processSettingsUrl(controlProps.setupImgUrl, pks, urlParams);
    const uploadUrl = processSettingsUrl(controlProps.uploadUrl, pks, urlParams);

    const hasImageUrl = processSettingsUrl(controlProps.hasImageUrl, pks, urlParams);
    const deleteImageUrl = processSettingsUrl(controlProps.deleteImageUrl, pks, urlParams);

    const elementId = Number(controlProps.urlParams?.elementId ?? 0);

    const poolingURL = processSettingsUrl(
        '/data/editor/element/extreport/:elementId/collect-image',
        fullPks,
        fullUrlParams
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: { segmentValueId: segmentValueId },
            })
        );
    }, [segmentValueId]);

    // Display Loading Mask
    const [isCollectionRunning, setIsCollectionRunning] = useState<boolean>(false);

    const [setupFormActive, setSetupFormActive] = useState<boolean>(false);
    const handleCloseSetupForm = function () {
        setSetupFormActive(false);
    };

    const handleOpenSetupForm = function () {
        setSetupFormActive(true);
    };

    const { t } = useBundleTranslation(['components/common/element_image']);

    const [showCollectImagePopup, setShowCollectImagePopup] = useState<boolean>(false);
    const handleCollectImageClick = function () {
        if (Number(controlProps.segmentId) > 0) {
            setShowCollectImagePopup(true);
        } else {
            saveAndCollectImage(0);
        }
    };

    const saveAndCollectImage = (segmentValueId: number) => {
        if (isDirty) {
            // Save form before collect values
            controlProps.form.formSave().then((response) => {
                if (response.data.status == 'OK') {
                    collectImage(segmentValueId);
                }
            });
        } else {
            collectImage(segmentValueId);
        }
    };

    const collectImage = (segmentValueId: number) => {
        setIsCollectionRunning(true);
        const onSuccess = function (data: any) {
            setIsCollectionRunning(false);
            // Update stamp to refresh Image
            setImageTimeStamp(String(Date.now()));
            // Select segment
            if (segmentValueId > 0) {
                onUserChangeSegmentValue(segmentValueId);
            }
            refetchHasImage();
        };
        const onError = function (data: any) {
            setIsCollectionRunning(false);
            alert(data.message);
        };

        const requestData: CollectImageRequestType & PoolingRequestData = {
            sv_id: segmentValueId,
            poolingCreateURL: poolingURL,
        };

        if (controlProps.generic) {
            requestData.generic = 'Y';
        }

        const { create } = pollingService<any, CollectImageRequestType>({ onSuccess, onError, requestData });
        create();
    };

    const { data: segmentValuesListRaw, isLoading: isSegmentLoading } = useQuery<Array<any>, Error>(
        [controlProps.name, 'getSegmentValues', controlProps.segmentId],
        () => (controlProps.segmentId == 0 ? [] : editorAPI.getSegmentValuesList(controlProps.segmentId))
    );

    const {
        hasImageToRemove,
        setHasImageToRemove,
        refetch: refetchHasImage,
    } = useHasImageToRemove(controlProps.name, hasImageUrl);

    const [segmentValuesList, setSegmentValuesList] = useState<Array<FormComponentValue>>([]);
    useEffect(() => {
        if (isSegmentLoading) {
            return;
        }
        const valuesList = prepareFormComponentValues(segmentValuesListRaw);
        if (valuesList.findIndex((v) => v.value == String(defaultSegmentValueId)) == -1) {
            if (valuesList.length) {
                setSegmentValueId(Number(valuesList[0].value));
            } else {
                setSegmentValueId(0);
            }
        } else {
            setSegmentValueId(defaultSegmentValueId);
        }
        setSegmentValuesList(valuesList);
    }, [segmentValuesListRaw]);

    const handleUploadImageClick = () => {
        if (isDirty) {
            // Save form before collect values
            controlProps.form.formSave().then((response) => {
                if (response.data.status == 'OK') {
                    setShowUploadImagePopup(true);
                }
            });
        } else {
            setShowUploadImagePopup(true);
        }
    };

    const [isDeletingImage, setIsDeletingImage] = useState(false);
    const handleRestoreImageClick = () => {
        setIsDeletingImage(true);
        instance
            .post(deleteImageUrl)
            .then((response) => {
                // Update stamp to refresh Image
                setImageTimeStamp(String(Date.now()));
                setHasImageToRemove(false);
                controlProps.form.hookFormSetValue('external_object_preview_filename', '');
                refetchHasImage();
            })
            .finally(() => setIsDeletingImage(false));
    };

    const [showUploadImagePopup, setShowUploadImagePopup] = useState<boolean>(false);

    const handleFileUploadResponse = (response: APIResponse<FileUploadResponse>) => {
        if (response.status == 'OK') {
            controlProps.form.hookFormSetValue('external_object_preview_filename', response.data.filename);
            // Update stamp to refresh Image
            setImageTimeStamp(String(Date.now()));
            refetchHasImage();
        } else {
            // TODO:
        }
    };

    const { isDirty } = useFormState({ control: controlProps.form.hookFormControl });

    const onUserChangeSegmentValue = (value: number) => {
        setSegmentValueId(value);
        instance.post('/data/service/internal/save-user-options', {
            key: `element_editor_${elementId}`,
            opts: { preview_sv_id: value },
        });
    };

    return (
        <Box>
            {(isCollectionRunning || isDeletingImage) && <LoadingPlaceholder />}
            {(Boolean(previewUrl.length) || Boolean(thumbnailUrl.length)) && (
                <Box sx={styles.preview}>
                    {!controlProps.generic && (
                        <Box>
                            <Typography variant="body1" gutterBottom component="div">
                                {t('homepage_preview_image')}
                            </Typography>
                            <Box sx={{ ...styles.previewImage, width: '552px', minHeight: '368px' }}>
                                {Boolean(previewUrl.length) && (
                                    <img data-test={`${controlProps.name}-preview`} src={previewUrl} />
                                )}
                            </Box>
                        </Box>
                    )}
                    <Box sx={controlProps.generic ? {} : styles.thumbnail}>
                        <Typography variant="body1" gutterBottom component="div">
                            {t('homepage_thumbnail')}
                        </Typography>

                        <Box sx={{ ...styles.previewImage, width: '137px' }}>
                            {Boolean(thumbnailUrl.length) && (
                                <img data-test={`${controlProps.name}-thumbnail`} src={thumbnailUrl} />
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
            {controlProps.segmentId > 0 && (
                <Box>
                    <ReactSelect
                        data={segmentValuesList}
                        addonLabel={t('preview_for')}
                        value={segmentValueId}
                        update={(value) => onUserChangeSegmentValue(Number(value))}
                        name="segmentValueId"
                    />
                </Box>
            )}
            {controlProps.collectImgEnabled && (
                <Box>
                    <Button
                        data-test={controlProps.name + '-collect-image-button'}
                        variant="light"
                        startIcon={isDirty ? <IconMi icon="save" /> : <IconMi icon="arrow-right-circle" />}
                        onClick={handleCollectImageClick}
                        color={'success'}
                    >
                        {t(isDirty ? 'save_and_collect_image' : 'collect_image')}
                    </Button>
                    {!controlProps.generic && (
                        <Button
                            onClick={handleOpenSetupForm}
                            variant="outlined"
                            sx={{ ml: 1 }}
                            startIcon={<IconMi icon="gear" />}
                        >
                            {t('setup_image')}
                        </Button>
                    )}
                    <Popup
                        maxWidth={'popupXl'}
                        settings={{
                            title: t('setup_image'),
                            noButtons: true,
                        }}
                        onHide={handleCloseSetupForm}
                        open={setupFormActive}
                    >
                        <SetupImageFormWrapper
                            onHide={handleCloseSetupForm}
                            settingsUrl={setupImgUrl}
                            src={originalUrl}
                            setupImgEnabled={controlProps.setupImgEnabled}
                            segmentValueId={segmentValueId}
                        />
                    </Popup>

                    {showCollectImagePopup && (
                        <SelectSegmentValuePopup
                            onHide={() => setShowCollectImagePopup(false)}
                            onConfirm={(segmentValueId) => {
                                setShowCollectImagePopup(false);
                                saveAndCollectImage(segmentValueId);
                            }}
                            segmentValuesList={segmentValuesList}
                        />
                    )}
                </Box>
            )}
            {controlProps.uploadImgEnabled && (
                <Stack spacing={2} direction="row">
                    <Box>
                        <Button
                            data-test={controlProps.name + '-upload-custom-image-button'}
                            variant="light"
                            startIcon={isDirty ? <IconMi icon="save" /> : <IconMi icon="upload" />}
                            onClick={handleUploadImageClick}
                        >
                            {t(isDirty ? 'save_and_upload_custom_image' : 'upload_custom_image')}
                        </Button>
                        {showUploadImagePopup && (
                            <UploadCustomImagePopup
                                uploadUrl={uploadUrl}
                                onHide={() => setShowUploadImagePopup(false)}
                                onConfirm={() => setShowUploadImagePopup(false)}
                                segmentValuesList={segmentValuesList}
                                segmentId={controlProps.segmentId}
                                elementId={elementId}
                                onFilePostResponse={handleFileUploadResponse}
                                generic={controlProps.generic}
                            />
                        )}
                    </Box>

                    <Box>
                        {hasImageToRemove && (
                            <Button
                                data-test={controlProps.name + '-restore-default-image-button'}
                                onClick={handleRestoreImageClick}
                                variant="light"
                                startIcon={<IconMi icon="refresh-cw" />}
                            >
                                {t('restore_default_image')}
                            </Button>
                        )}
                    </Box>
                </Stack>
            )}
        </Box>
    );
}
