import { Chart as HChart } from 'highcharts';
import { ReactElement } from 'react';
import { ChartPoint } from 'components/metric/chart';
import { alpha, Box } from '@mui/material';

const TooltipWidth = 226;
// Real height IS NOT constant, this is default basic value for simplest point.
const TooltipHeight = 160;
// Space between tooltip and point for X Axis
const TooltipMargin = 24;

export default function TooltipContainer({
    point,
    children,
    chart,
}: {
    point: ChartPoint | null;
    chart: HChart;
    children: ReactElement;
}) {
    if (point == null) {
        return <></>;
    }
    /* Calculate tooltip position START */
    // var container_width = this.container.outerWidth();
    // var tooltip_width   = this.settings.isPreview ? 135 : 235;

    // Push tooltip to top for TooltipMargin px
    const pointY = (point.plotY ?? 0) - TooltipMargin;
    const pointX = point.plotX ?? 0;

    // Add scroll offset (usually 0 for X), and Plot margins (spase reserved by Axis labels + margin to main chart area)
    let top = pointY + chart.container.offsetTop + chart.plotTop;
    let left = pointX + chart.container.offsetLeft + chart.plotLeft;

    if (pointX + TooltipWidth < chart.plotWidth) {
        // Drop Right
        left += TooltipMargin;
    } else {
        // Drop Left
        left -= TooltipWidth + TooltipMargin;
    }
    // Space from page top to Chart Range Selector
    const totalHeight = chart.plotHeight + chart.container.offsetTop + chart.plotTop;
    if (top + TooltipHeight > totalHeight) {
        // Drop Top
        top = totalHeight - TooltipHeight;
    }

    return (
        <Box
            sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                borderRadius: 1,
                boxShadow: (theme) => `0px 1px 4px 0px ${alpha(theme.palette.text.primary, 0.32)}`,
                width: TooltipWidth,
                top: top,
                left: left,
                position: 'absolute',
                color: (theme) => alpha(theme.palette.text.primary, 0.8),
                zIndex: 3,
            }}
        >
            {children}
        </Box>
    );
}
