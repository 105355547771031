import React, { useEffect, useState } from 'react';
import { ElementViewerPropsType } from 'components/element-viewer';
import {
    MetricChartCompareLineType,
    MetricChartDataType,
    MetricChartOverlayType,
    MetricChartState,
    MetricChartStateLegend,
    MetricChartViewsMode,
} from './chart';
import { Box, Button, Container, Stack } from '@mui/material';
import useBundleTranslation from 'i18n';
import MetricChartCollaborationControls from 'components/metric/MetricChartCollaborationControls';
import { metricAPI } from 'api/viewer/metric';
import { useQueryClient } from '@tanstack/react-query';
import ControlsPanel from 'components/element-viewer/ControlsPanel';
import Filter, { FilterType } from 'components/external-reference/Filter';
import { StaticAddon } from 'components/common/static-addon/StaticAddon';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { viewerMetricAPI } from 'api/viewer/metric';
import IconMi from 'components/common/icon/IconMi';
import TopControlsWrapper from 'components/element-viewer/common/TopControlsWrapper';
import useWallContext, { WallContext } from 'components/wall/hooks/useWallContext';
import MetricChartWrapper from 'components/metric/chart/MetricChartWrapper';
import { CollaborationEventForm } from 'components/metric/collaboration-controls/CollaborationEvent';
import { CollaborationAnnotationForm } from 'components/metric/collaboration-controls/CollaborationAnnotation';
import {
    handleChartStateLegendChange,
    handleChartStateRangeChange,
    handleChartViewChange,
} from 'components/metric/index';
import ElementPreviewSummary from 'app/home/tile/preview/ElementPreviewSummary';
import usePageTitleCrumbs from 'components/element-viewer/hooks/usePageTitleCrumbs';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';
import HeadingElementData from 'components/element-viewer/HeadingElementData';

export interface EventCalendarType {
    notableEventCalendarId: number;
    name: string;
    eventInterval: 'point' | 'range';
    // scopeLimitedBySegmentId: null;
}

export interface AssociatedMeasurementIntervals {
    displaySequence: number;
    intervalId: number;
    intervalName: string;
    linkToElement: number;
    linkToSegment: number;
}

export interface ChartMetricsInfoType {
    currValue: string;
    intervalUnit: string;
    maxReachedOn: string;
    maxValue: string;
    measurementIntervalName: string;
    metricDescr: string;
    metricElementId: number;
    metricMovingAverageInterval: string;
    metricTileDisplayPctVariance: number | null;
    minReachedOn: string;
    minValue: string;
    movingAverageValue: string;
    pctVarianceText: string;
    pureMetricName: string;
    segmentValueDisplayName: string;
    segmentValueId: number;
}

export interface MetricViewerDataType {
    chartData: MetricChartDataType;
    manualCalendars: Array<EventCalendarType>;
    measIntervals: Array<AssociatedMeasurementIntervals>;
    metrics: Array<ChartMetricsInfoType>;
    userChartOverlay: number;
}

export const SetChartStateContext = React.createContext<any>(null);

const overlaysPalette = [
    '#00FFFF',
    '#00CCFF',
    '#FF99CC',
    '#FFCC99',
    '#FFFF99',
    '#CCFFCC',
    '#CCFFFF',
    '#99CCFF',
    '#CC99FF',
    '#993366',
    '#FF00FF',
    '#FFCC00',
    '#FFFF00',
    '#00FF00',
];

export default function MetricViewer({
    elementInfo,
    viewerRequestExtraData,
    targets,
    alertStatus,
    segmentValueId,
    onFavoritesChange,
    filters,
    related,
    onRelatedElementSelection,
    onFilterChange,
    refetch,
    setUserChartInterval,
    embeddingType,
}: ElementViewerPropsType<MetricViewerDataType>) {
    const { t } = useBundleTranslation('components/metric/chart');
    const uco = Number(viewerRequestExtraData.userChartOverlay);

    const [chartState, setChartState] = useState<MetricChartState>(viewerRequestExtraData.chartData.state);
    useEffect(() => {
        if (JSON.stringify(viewerRequestExtraData.chartData.state) != JSON.stringify(chartState)) {
            setChartState(viewerRequestExtraData.chartData.state);
        }
    }, [JSON.stringify(viewerRequestExtraData.chartData.state)]);
    const [defaultChartState] = useState<MetricChartState>(structuredClone(viewerRequestExtraData.chartData.state));
    const [chartData, setChartData] = useState(viewerRequestExtraData.chartData);
    useEffect(() => {
        setChartData(viewerRequestExtraData.chartData);
    }, [viewerRequestExtraData.chartData]);

    // CompareLines
    const handleAddCompareLine = (compareLines: Array<MetricChartCompareLineType>) => {
        setChartState((prevState) => {
            return { ...prevState, compareLines: prevState.compareLines.concat(compareLines) };
        });
    };
    const handleRemoveCompareLines = (compareLines: Array<Number>) => {
        let newList = chartState.compareLines.slice();
        compareLines.forEach((id) => {
            const index = newList.findIndex((line) => Number(line.id) == id);
            if (index != -1) {
                newList.splice(index, 1);
            }
        });

        setChartState((prevState) => {
            return { ...prevState, compareLines: newList };
        });
    };

    const handleUpdateCompareLine = (id: number, color: string, dashStyle: string) => {
        let newList = chartState.compareLines.slice();
        const index = newList.findIndex((line) => Number(line.id) == id);
        if (index != -1) {
            newList[index] = { ...newList[index], color: color, dashStyle: dashStyle };
        }
        setChartState((prevState) => {
            return { ...prevState, compareLines: newList };
        });
    };

    // Overlays
    const handleAddOverlays = (overlays: Array<MetricChartOverlayType>) => {
        const usedColors: { [key: string]: number } = {};
        overlaysPalette.forEach((color) => (usedColors[color] = 0));

        setChartState((prevState) => {
            prevState.overlays.forEach((overlay) => {
                if (usedColors[overlay.color] != undefined) {
                    usedColors[overlay.color]++;
                }
            });
            overlays.forEach((overlay, index) => {
                let minValue = -1;
                let color = '';
                for (const [key, value] of Object.entries<number>(usedColors)) {
                    if (minValue > value || minValue == -1) {
                        minValue = value;
                        color = key;
                    }
                }
                usedColors[color]++;
                overlays[index].color = color;
                // overlay.metric_chart_display_type = 'column';
            });

            return { ...prevState, overlays: prevState.overlays.concat(overlays) };
        });
    };
    const handleRemoveOverlays = (overlays: Array<{ elementId: number; segmentValueId: number }>) => {
        let newList = chartState.overlays.slice();
        overlays.forEach((row) => {
            const index = newList.findIndex(
                (overlay) =>
                    Number(overlay.element_id) == row.elementId &&
                    Number(overlay.segment_value_id) == row.segmentValueId
            );
            if (index != -1) {
                newList.splice(index, 1);
            }
        });

        setChartState((prevState) => {
            return { ...prevState, overlays: newList };
        });
    };

    const handleUpdateOverlay = (overlay: MetricChartOverlayType) => {
        let newList = chartState.overlays.slice();
        const index = newList.findIndex(
            (line) =>
                Number(line.element_id) == Number(overlay.element_id) &&
                Number(line.segment_value_id) == Number(overlay.segment_value_id)
        );
        if (index != -1) {
            newList[index] = {
                ...newList[index],
                color: overlay.color,
                dashStyle: overlay.dashStyle,
                axis: overlay.axis,
                metric_chart_display_type: overlay.metric_chart_display_type,
            };
        }
        setChartState((prevState) => {
            return { ...prevState, overlays: newList };
        });
    };

    const queryClient = useQueryClient();

    const [prevState, setPrevState] = useState(viewerRequestExtraData.chartData.state);
    useEffect(() => {
        if (JSON.stringify(prevState) == JSON.stringify(chartState)) {
            return;
        }
        setPrevState(chartState);
        metricAPI.saveChartState(elementInfo.row.elementId, segmentValueId, uco, chartState).then((response) => {
            if (response.uco_id) {
                if (response.uco_id != uco && setUserChartInterval) {
                    setUserChartInterval(response.uco_id);
                }
            }
            // Refresh Grid
            queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey[0] === 'overlayMetricsGrid' ||
                    query.queryKey[0] === 'compareLinesGrid' ||
                    query.queryKey[0] === 'eventCalendarGrid',
            });
            queryClient.resetQueries({
                predicate: (query) => query.queryKey[0] === 'addCompareLineListCheckBox',
            });
        });
    }, [chartState]);

    usePageTitleCrumbs(elementInfo);

    const [isSummaryVisible, setIsSummaryVisible] = useState<boolean>(elementInfo.row.showMetricSummaryInfoInd);
    const handleToggleSummaryVisibility = () => {
        setIsSummaryVisible((prevState) => {
            const newState = !prevState;
            viewerMetricAPI.setSummaryTabsState(elementInfo.row.elementId, newState);
            return newState;
        });
    };

    const handleChartReset = () => {
        if (!confirm(t('alert_are_you_want_to_revert_default_state_metric_chart'))) {
            return;
        }
        let newChartRange = defaultChartState.range;
        if (defaultChartState.range == chartState.range) {
            if (typeof chartState.range == 'undefined') {
                newChartRange = null;
            } else {
                newChartRange = undefined;
            }
        }
        setChartState((prevState) => {
            return {
                compareLines: [],
                overlays: [],
                legend: {},
                range: newChartRange,
            };
        });
    };

    const { wallActions, setWallActions, addOnUpdateCallback, triggerOnUpdate } = useWallContext(true);

    useEffect(() => {
        addOnUpdateCallback('chartData', () => {
            refetch();
        });
    }, []);

    const [editAnnotationId, setEditAnnotationId] = useState<number>(0);
    const [editEventId, setEditEventId] = useState<number>(0);

    const defaultMeasurementInterval = viewerRequestExtraData.measIntervals.find(
        (row) => row.linkToElement == elementInfo.row.elementId && row.linkToSegment == segmentValueId
    );

    const [associatedMeasurementInterval, setAssociatedMeasurementInterval] = useState(
        defaultMeasurementInterval ? defaultMeasurementInterval.intervalId : 0
    );

    const isVisibleSegment = useEmbeddingContextVisible('segment');

    if (embeddingType == 'short') {
        return (
            <Stack direction="row">
                <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
                    <Stack direction="row">
                        <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
                            <MetricChartWrapper
                                height={600}
                                elementInfo={elementInfo}
                                segmentValueId={segmentValueId}
                                chartData={chartData}
                                chartState={chartState}
                                updateChartStateLegend={(id: string, legendState: MetricChartStateLegend) =>
                                    handleChartStateLegendChange(setChartState, id, legendState)
                                }
                                updateChartStateRange={(chartingInterval) =>
                                    handleChartStateRangeChange(setChartState, chartingInterval)
                                }
                                updateChartView={(newView: MetricChartViewsMode) =>
                                    handleChartViewChange(setChartState, newView)
                                }
                                userChartOverlay={uco}
                                manualCalendars={viewerRequestExtraData.manualCalendars}
                                refetch={refetch}
                            />
                        </Box>
                        <Box>
                            {!isSummaryVisible && (
                                <Stack
                                    sx={{
                                        pl: 2,
                                        pt: '255px',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={handleToggleSummaryVisibility}
                                        sx={{ height: '80px' }}
                                        className={'min-width--icon'}
                                    >
                                        <IconMi icon={'chevron-left'} fontSize="16" />
                                    </Button>
                                </Stack>
                            )}
                        </Box>
                    </Stack>
                </Box>
                <ElementPreviewSummary
                    elementInfo={elementInfo}
                    viewerRequestExtraData={viewerRequestExtraData}
                    onToggleVisibility={handleToggleSummaryVisibility}
                    isVisible={isSummaryVisible}
                />
            </Stack>
        );
    }

    return (
        <SetChartStateContext.Provider value={setChartState}>
            <TopControlsWrapper>
                <HeadingElementData element={elementInfo}>
                    {((isVisibleSegment && filters.length > 0) ||
                        viewerRequestExtraData.measIntervals.length > 1 ||
                        related.length > 0) && (
                        <>
                            {isVisibleSegment &&
                                filters.map((filter: FilterType) => {
                                    return (
                                        <Stack key={filter.filterId} direction="row">
                                            <StaticAddon>{filter.title}</StaticAddon>
                                            <Box data-test={'dimension-select-wrapper'} sx={{ width: 164 }}>
                                                <Filter
                                                    elementId={elementInfo.row.elementId}
                                                    context="filter"
                                                    onFilterChange={onFilterChange}
                                                    filter={filter}
                                                />
                                            </Box>
                                        </Stack>
                                    );
                                })}
                            {viewerRequestExtraData.measIntervals.length > 1 && (
                                <Box sx={{ width: 164 }}>
                                    <ReactSelect
                                        value={associatedMeasurementInterval}
                                        update={(value) => {
                                            const newInterval = viewerRequestExtraData.measIntervals.find(
                                                (row) => row.intervalId == value
                                            );
                                            if (newInterval) {
                                                onRelatedElementSelection(
                                                    `/chart/${newInterval.linkToElement}/segment/${newInterval.linkToSegment}`
                                                );
                                            }
                                            setAssociatedMeasurementInterval(Number(value));
                                        }}
                                        data={viewerRequestExtraData.measIntervals.map((row) => ({
                                            value: String(row.intervalId),
                                            label: row.intervalName,
                                        }))}
                                    />
                                </Box>
                            )}
                            {related.length > 0 && (
                                <Box sx={{ width: 164 }}>
                                    <ReactSelect
                                        placeholder={'See Related'}
                                        value={null}
                                        update={(value) => {
                                            onRelatedElementSelection(value);
                                        }}
                                        data={related}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </HeadingElementData>
                <ControlsPanel
                    userChartOverlay={uco}
                    element={elementInfo}
                    segmentValueId={segmentValueId}
                    onFavoritesChange={onFavoritesChange}
                    targets={targets}
                    alertStatus={alertStatus}
                />
            </TopControlsWrapper>
            <Box
                sx={{
                    overflow: 'auto',
                    flexGrow: 1,
                    pt: 2,
                    px: (theme) => theme.size.pxValue(theme.size.containerIndentX),
                }}
                className={'scroll-content-container'}
            >
                <Box data-test={'metric-chart-container'}>
                    <WallContext.Provider
                        value={{
                            wallActions: wallActions,
                            setWallActions: (actions) => setWallActions(actions),
                            addOnUpdateCallback: addOnUpdateCallback,
                            triggerOnUpdate: triggerOnUpdate,
                            editAnnotationId: editAnnotationId,
                            setEditAnnotationId: setEditAnnotationId,
                            editEventId: editEventId,
                            setEditEventId: setEditEventId,
                        }}
                    >
                        <AnnotationEditPopup
                            refetch={refetch}
                            elementId={elementInfo.row.elementId}
                            segmentValueId={segmentValueId}
                        />
                        <EventEditPopup
                            refetch={refetch}
                            elementId={elementInfo.row.elementId}
                            segmentValueId={segmentValueId}
                        />
                        <Stack direction="row">
                            <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
                                <Stack direction="row">
                                    <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
                                        <MetricChartWrapper
                                            height={600}
                                            elementInfo={elementInfo}
                                            segmentValueId={segmentValueId}
                                            chartData={chartData}
                                            chartState={chartState}
                                            updateChartStateLegend={(id: string, legendState: MetricChartStateLegend) =>
                                                handleChartStateLegendChange(setChartState, id, legendState)
                                            }
                                            updateChartStateRange={(chartingInterval) =>
                                                handleChartStateRangeChange(setChartState, chartingInterval)
                                            }
                                            updateChartView={(newView: MetricChartViewsMode) =>
                                                handleChartViewChange(setChartState, newView)
                                            }
                                            userChartOverlay={uco}
                                            manualCalendars={viewerRequestExtraData.manualCalendars}
                                            refetch={refetch}
                                        />
                                        <MetricChartCollaborationControls
                                            elementInfo={elementInfo}
                                            elementId={elementInfo.row.elementId}
                                            segmentValueId={segmentValueId}
                                            elementType={elementInfo.row.type}
                                            manualCalendars={viewerRequestExtraData.manualCalendars}
                                            userChartOverlay={uco}
                                            addCompareLines={handleAddCompareLine}
                                            removeCompareLines={handleRemoveCompareLines}
                                            updateCompareLine={handleUpdateCompareLine}
                                            addOverlays={handleAddOverlays}
                                            removeOverlays={handleRemoveOverlays}
                                            updateOverlay={handleUpdateOverlay}
                                            onReset={handleChartReset}
                                            chartData={chartData}
                                            refetch={refetch}
                                            isSummaryVisible={isSummaryVisible}
                                        />
                                    </Box>
                                    <Box>
                                        {!isSummaryVisible && (
                                            <Stack
                                                sx={{
                                                    pl: 2,
                                                    pt: '255px',
                                                }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleToggleSummaryVisibility}
                                                    sx={{ height: '80px' }}
                                                    className={'min-width--icon'}
                                                >
                                                    <IconMi icon={'chevron-left'} fontSize="16" />
                                                </Button>
                                            </Stack>
                                        )}
                                    </Box>
                                </Stack>
                            </Box>
                            <ElementPreviewSummary
                                elementInfo={elementInfo}
                                viewerRequestExtraData={viewerRequestExtraData}
                                onToggleVisibility={handleToggleSummaryVisibility}
                                isVisible={isSummaryVisible}
                            />
                        </Stack>
                    </WallContext.Provider>
                </Box>
            </Box>
        </SetChartStateContext.Provider>
    );
}

function AnnotationEditPopup({
    elementId,
    segmentValueId,
    refetch,
}: {
    elementId: number;
    segmentValueId: number;
    refetch: () => void;
}) {
    const { editAnnotationId, setEditAnnotationId } = useWallContext(false);

    if (editAnnotationId == 0) {
        return null;
    }

    return (
        <CollaborationAnnotationForm
            metricInstanceId={0}
            elementId={elementId}
            segmentValueId={segmentValueId}
            onHide={() => {
                setEditAnnotationId(0);
            }}
            afterSave={() => refetch()}
            annotationId={editAnnotationId}
        />
    );
}

function EventEditPopup({
    elementId,
    segmentValueId,
    refetch,
}: {
    elementId: number;
    segmentValueId: number;
    refetch: () => void;
}) {
    const { editEventId, setEditEventId } = useWallContext(false);
    if (editEventId == 0) {
        return null;
    }

    return (
        <CollaborationEventForm
            elementId={elementId}
            segmentValueId={segmentValueId}
            onHide={() => setEditEventId(0)}
            eventId={editEventId}
            afterSave={() => refetch()}
        />
    );
}
