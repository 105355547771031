import { alpha, Box, Button, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { EventCalendarType } from 'components/metric/MetricViewer';
import useBundleTranslation from 'i18n';
import CollaborationEvent from 'components/metric/collaboration-controls/CollaborationEvent';
import { MetricChartCompareLineType, MetricChartDataType, MetricChartOverlayType } from 'components/metric/chart';
import OverlayMetrics from 'components/metric/collaboration-controls/overlay-metrics/OverlayMetrics';
import ViewerWall from 'components/wall/ViewerWall';
import ExpertCommentaryWall from 'components/wall/ExpertCommentaryWall';
//Mock
import mockCollaborativeData from 'components/wall/mock/collaborative-data.json';
import { UserAuth } from 'store/auth';
import { useAppSelector } from 'store/hooks';
import CollaborationAnnotation from 'components/metric/collaboration-controls/CollaborationAnnotation';
import useWallContext from 'components/wall/hooks/useWallContext';
import { useIsAdminOrHasPrivilege, useIsUserHasPrivilege } from 'hooks/useUserPrivilege';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';
import { ElementType } from 'components/element-viewer';

export default function MetricChartCollaborationControls({
    elementInfo,
    manualCalendars,
    elementId,
    segmentValueId,
    elementType,
    userChartOverlay,
    addCompareLines,
    removeCompareLines,
    updateCompareLine,
    addOverlays,
    removeOverlays,
    updateOverlay,
    onReset,
    chartData,
    refetch,
    isSummaryVisible,
}: {
    elementInfo: ElementType;
    manualCalendars: Array<EventCalendarType>;
    elementId: number;
    segmentValueId: number;
    elementType: string;
    userChartOverlay: number;
    addCompareLines: (compareLines: Array<MetricChartCompareLineType>) => void;
    removeCompareLines: (compareLines: Array<number>) => void;
    updateCompareLine: (id: number, color: string, dashStyle: string) => void;
    addOverlays: (overlays: Array<MetricChartOverlayType>) => void;
    removeOverlays: (overlays: Array<{ elementId: number; segmentValueId: number }>) => void;
    updateOverlay: (overlay: MetricChartOverlayType) => void;
    onReset: () => void;
    chartData: MetricChartDataType;
    refetch: () => void;
    isSummaryVisible: boolean;
}) {
    const { t } = useBundleTranslation('components/metric/chart');

    const userAuth: UserAuth = useAppSelector((state) => state.auth);
    const userId = userAuth?.userInfo?.user_id ?? 0;

    const SeparatorItem = () => (
        <Box
            sx={{
                height: 16,
                width: '1px',
                backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                mx: 1.5,
            }}
        />
    );

    const { wallActions } = useWallContext(false);

    const canAddAnnotation = useIsAdminOrHasPrivilege('PRIV_ADD_ANNOTATION');
    const hasWall = useEmbeddingContextVisible('wall');

    const canAddExpAnnotationOwner = useIsUserHasPrivilege('PRIV_CAN_ADD_EXPERT_COMMENTARY_ONLY_IF_OWNER');
    const isOwner =
        userId > 0 &&
        ['businessOwnerId', 'dataStewardId', 'technicalOwnerId'].some(
            (type) => (elementInfo as any).row[type] == userId
        );
    const canAddExpAnnotation = useIsAdminOrHasPrivilege('PRIV_CAN_ADD_EXPERT_COMMENTARY');
    const expertCommentaryAllowEdit = (isOwner && canAddExpAnnotationOwner) || canAddExpAnnotation;

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    pt: 3,
                }}
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <OverlayMetrics
                        elementId={elementId}
                        segmentValueId={segmentValueId}
                        userChartOverlay={userChartOverlay}
                        addCompareLines={addCompareLines}
                        removeCompareLines={removeCompareLines}
                        updateCompareLine={updateCompareLine}
                        addOverlays={addOverlays}
                        removeOverlays={removeOverlays}
                        updateOverlay={updateOverlay}
                    />
                    {manualCalendars.length > 0 && (
                        <CollaborationEvent
                            afterSave={() => refetch()}
                            eventId={0}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                        >
                            <Button variant="light" startIcon={<IconMi icon="add-event" />}>
                                {t('add_event')}
                            </Button>
                        </CollaborationEvent>
                    )}
                    {canAddAnnotation && (
                        <CollaborationAnnotation
                            annotationId={0}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                            metricInstanceId={0}
                            afterSave={() => refetch()}
                        >
                            <Button variant="light" startIcon={<IconMi icon="add-annotation" />}>
                                {t('add_annotation')}
                            </Button>
                        </CollaborationAnnotation>
                    )}
                </Stack>
                <Stack direction="row" alignItems="center">
                    {expertCommentaryAllowEdit && (
                        <>
                            <Button
                                variant="outlined"
                                startIcon={<IconMi icon="briefcase" />}
                                onClick={() => {
                                    wallActions.setExpertAnalysisField(true);
                                }}
                            >
                                {t('add_expert_analysis')}
                            </Button>
                            <SeparatorItem />
                        </>
                    )}
                    <Button variant="outlined" onClick={onReset} className={'min-width--icon'}>
                        <IconMi icon="rotate-ccw" />
                    </Button>
                </Stack>
            </Stack>
            {hasWall && (
                <Box sx={{ pl: isSummaryVisible ? 0 : '44px' }}>
                    <Box sx={{ pt: '30px', mx: 'auto', width: '552px' }}>
                        <Box sx={{ pb: 2 }}>
                            <ExpertCommentaryWall
                                data={{}}
                                elementId={elementId}
                                segmentValueId={segmentValueId}
                                userId={userId}
                                elementType={elementType}
                                allowEdit={expertCommentaryAllowEdit}
                            />
                        </Box>
                        <ViewerWall
                            wallType="chart"
                            data={{}}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                            elementType={elementType}
                            userId={userId}
                            collaborativeData={chartData}
                            // collaborativeData={mockCollaborativeData}
                        />
                    </Box>
                </Box>
            )}
        </>
    );
}
