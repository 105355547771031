import React from 'react';
import { FormLayoutComposer, IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import useBundleTranslation from 'i18n';
import { ActSubmit } from 'components/common/form/act';

export default function ElementShareForm(props: IFormLayoutProps) {
    const { t } = useBundleTranslation();
    props.hookForm.form.formAct.overrideAct(
        'submit',
        class extends ActSubmit {
            exec = async (data: any) => {
                return super.exec(data).then((response) => {
                    if (response?.data?.status == 'OK') {
                        alert(t('your_content_shared'));
                    }
                    return response;
                });
            };
        }
    );

    return <FormLayoutComposer props={props} />;
}
