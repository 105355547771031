import useBundleTranslation from 'i18n';
import { Params as UrlParams } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { viewerAPI } from 'api/viewer';
import {
    AnyElementsViewerDataType,
    buildElementURLOnFilters,
    ElementViewerDataResponse,
    ElementViewerDataType,
    prepareSegments,
} from './index';
import MetricViewer, { MetricViewerDataType } from 'components/metric/MetricViewer';
import ExternalReportViewer, {
    ExternalReportViewerDataType,
    useGetSegmentValueIdFromFilters,
} from 'app/extreport/ExternalReportViewer';
import { FilterType, useApplyFilterValueMap } from 'components/external-reference/Filter';
import { APIResponse, DashboardElementType } from 'tools/types';
import { FormComponentValue } from 'components/common/form/layout/control';
import { ElementTypeNS } from 'tools/elementType';
import AnnouncementsComponent from 'components/announcements/AnnouncementsComponent';
import { favoritesAPI } from 'api/favorites';
import ReportViewer, { ReportViewerDataType } from 'app/report/ReportViewer';
import ExternalContentViewer, { ExternalContentViewerDataType } from 'app/ext-content/ExternalContentViewer';
import RatingComponentPageWrapper from '../rating/RatingComponentPageWrapper';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { ElementDisplayContextType } from 'components/metric/chart/MetricChartWrapper';
import { Box, Stack } from '@mui/material';
import { externalReportAPI } from 'api/external-report';
import { instance } from 'api/api';
import { ElementEmbeddingType } from 'components/element-viewer/IFrameElementViewer';

export default function ElementViewer({ embeddingType }: { embeddingType?: ElementEmbeddingType }) {
    const { t } = useBundleTranslation();

    const urlParams: UrlParams = useParams();
    const elementId = Number(urlParams.elementId);
    const viewMode = urlParams?.view;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const [filters, setFilters] = useState<Array<FilterType>>([]);
    const [isAnnouncementExpanded, setIsAnnouncementExpanded] = useState(false);

    const handleFilterChange = function (filterId: number, newValue: any) {
        const list = filters.slice();
        // Convert string|number to array
        if (!Array.isArray(newValue)) {
            newValue = newValue == '' ? [] : [newValue];
        }
        list.find((f) => f.filterId == filterId)!.selectedValues = newValue;
        const fullList = useApplyFilterValueMap(list);
        setFilters(fullList);

        const newSegmentValueId = useGetSegmentValueIdFromFilters(fullList);
        onSegmentValueChange(newSegmentValueId);
    };
    const [userChartOverlay, setUserChartOverlay] = useState<number>(Number(urlParams.uco ?? 0));
    const ucoRef = useRef(userChartOverlay);
    // On userChartOverlay change
    useEffect(() => {
        ucoRef.current = userChartOverlay;
        if (viewerData?.elementData && userChartOverlay) {
            const newURL = buildElementURLOnFilters(viewerData.elementData, segmentValueId, userChartOverlay, viewMode);
            window.history.replaceState(null, document.title, newURL);
            refetch();
        }
    }, [userChartOverlay]);

    const [searchParams] = useSearchParams();
    // SegmentValueId is not a part of key
    const queryKey = `elementViewer_${elementId}`;
    const {
        status: viewerDataRequestStatus,
        data: viewerRequestData,
        error: viewerRequestError,
        refetch,
        isRefetching,
        remove: removeViewerData,
    } = useQuery<ElementViewerDataResponse, Error>(
        [queryKey],
        () => {
            const bookmark = urlSearchParams.get('bookmark');
            return viewerAPI.loadElementData(
                elementId,
                segmentValueId,
                ucoRef.current,
                false,
                false,
                bookmark == null ? 0 : Number(bookmark),
                searchParams
            );
        },
        { refetchOnMount: true }
    );

    useEffect(() => {
        return () => {
            removeViewerData();
        };
    }, []);

    const { elementType, viewerData, setViewerData, segmentValueId, setSegmentValueId, viewerExtraData } =
        usePrepareViewerData(userChartOverlay, setUserChartOverlay, setFilters, 'viewer', viewerRequestData);

    useEffect(() => {
        if (elementType != 'metric' && elementType != 'multi-metric chart') {
            return;
        }
        const uco = (viewerExtraData as MetricViewerDataType).userChartOverlay;
        if (uco) {
            if (userChartOverlay != uco) {
                setUserChartOverlay(uco);
                // removeViewerData();
            }
        }
    }, [viewerExtraData]);

    useEffect(() => {
        if (!elementType) {
            return;
        }
        if (elementType != 'metric' && elementType != 'multi-metric chart') {
            return;
        }

        instance
            .get<APIResponse<any>>(`/data/viewer/element/get-uco/${elementId}/segment/${segmentValueId}`)
            .then((response) => {
                if (response.data.status == 'OK' && response.data.data && response.data.data != userChartOverlay) {
                    setUserChartOverlay(response.data.data);
                } else {
                    refetch();
                }
            });
    }, [segmentValueId]);

    const onSegmentValueChange = (newSegmentValueId: number) => {
        if (newSegmentValueId == segmentValueId) {
            return;
        }
        setSegmentValueId(newSegmentValueId);
        if (elementType == 'metric' || elementType == 'multi-metric chart') {
            // Make request to save selected segment value as default
            externalReportAPI.bookmark.saveFilters(elementId, newSegmentValueId, []);
        } else {
            if (viewerData?.elementData) {
                const newURL = buildElementURLOnFilters(
                    viewerData.elementData,
                    newSegmentValueId,
                    userChartOverlay,
                    viewMode
                );
                window.history.replaceState(null, document.title, newURL);
            }
        }
    };

    const handleFiltersChange = function (newFilters: Array<FilterType>) {
        // Fix for Text Filters
        const list = newFilters.slice();
        list.forEach((filter) => {
            if (filter.filterInput == 'text') {
                let rawValue: any = [];
                filter.selectedValues.forEach((sv) => {
                    const fv = filter.values.find((v) => v.value_id == sv);
                    if (fv) {
                        rawValue.push(fv.value);
                    }
                });

                if (rawValue.length) {
                    filter.selectedValues = rawValue;
                    filter.defaultValues = rawValue;
                }
            }
        });
        setFilters(list);
    };

    const related: Array<FormComponentValue> = useMemo<Array<FormComponentValue>>(() => {
        if (!viewerData?.elementData?.related) {
            return [];
        }

        const related = Array.isArray(viewerData.elementData.related?.related)
            ? viewerData.elementData.related.related
            : [];

        const drill = Array.isArray(viewerData.elementData.related?.drillDown)
            ? viewerData.elementData.related.drillDown
            : [];

        return related
            .map((r) => {
                return {
                    label: r.elementName,
                    value:
                        '/' +
                        ElementTypeNS.getPathByElementType(r.elementType) +
                        `/${r.elementId}/segment/${r.segmentId}`,
                    props: { icon: { type: 'mi', value: ElementTypeNS.getIconNameByElementType(r.elementType) } },
                };
            })
            .concat(
                drill.map((r) => {
                    return {
                        label: r.label,
                        value:
                            '/' + ElementTypeNS.getPathByElementType(r.type) + `/${r.reportId}/segment/${r.segmentId}`,
                        props: { icon: { type: 'mi', value: ElementTypeNS.getIconNameByElementType(r.type) } },
                    };
                })
            );
    }, [viewerData?.elementData?.related]);

    if (!viewerData) {
        return <span>{t('loading___')}</span>;
    }

    const handleRelatedElementSelection = (value: string) => {
        window.location.href = value;
    };

    if (
        viewerData &&
        viewerData.elementData &&
        (elementType == 'external report' || elementType == 'other external content') &&
        viewerData.elementData.row.externalReportDisplay === 'external'
    ) {
        window.location.href = viewerData.elementData.row.externalReportUrl;
        return null;
    }

    // Element was added or removed to some favorites
    // Refresh actual favorites list
    const handleFavoritesChange = () => {
        favoritesAPI.getElementFavoritesList(elementId, segmentValueId, true).then((result) => {
            setViewerData((prevState) => {
                const newState = structuredClone(prevState);
                newState.elementData.inFavorites = result.slice();
                return newState;
            });
        });
    };

    const viewerProps = {
        embeddingType: embeddingType,
        elementInfo: viewerData?.elementData,
        targets: viewerData.targets,
        alertStatus: viewerData.alertStatus,
        related: related,
        viewerRequestData: viewerData,
        filters: filters,
        onFiltersChange: handleFiltersChange,
        onSegmentValueChange: onSegmentValueChange,
        segmentValueId: segmentValueId,
        onFavoritesChange: handleFavoritesChange,
        onRelatedElementSelection: handleRelatedElementSelection,
        onFilterChange: handleFilterChange,
        refetch: refetch,
        setUserChartInterval: setUserChartOverlay,
    };

    return (
        <Stack sx={{ height: '100%' }}>
            {viewerDataRequestStatus === 'loading' ? (
                <span>{t('Loading...')}</span>
            ) : viewerDataRequestStatus === 'error' ? (
                <span>Error: {viewerRequestError!.message}</span>
            ) : (
                <>
                    {isRefetching && <LoadingPlaceholder />}
                    {typeof embeddingType == 'undefined' && (
                        <>
                            <Box
                                sx={{
                                    flexShrink: 0,
                                    maxHeight: '50%',
                                    '&.announcement-wrapper--expanded': {
                                        maxHeight: 'none',
                                        height: '100%',
                                    },
                                    '#announcementList': {
                                        pt: 1,
                                        px: 2,
                                    },
                                }}
                                className={
                                    'announcement-wrapper-el ' +
                                    (isAnnouncementExpanded ? 'announcement-wrapper--expanded' : '')
                                }
                            >
                                <AnnouncementsComponent
                                    elementId={elementId}
                                    onChangeCollapsedState={(isExpanded) => {
                                        setIsAnnouncementExpanded(isExpanded);
                                    }}
                                />
                            </Box>
                            <RatingComponentPageWrapper itemType={'element'} itemId={elementId.toString()} />
                        </>
                    )}

                    {(elementType == 'metric' || elementType == 'multi-metric chart') && (
                        <MetricViewer
                            {...viewerProps}
                            viewerRequestExtraData={viewerExtraData as MetricViewerDataType}
                        />
                    )}
                    {elementType == 'external report' && (
                        <ExternalReportViewer
                            {...viewerProps}
                            viewerRequestExtraData={viewerExtraData as ExternalReportViewerDataType}
                        />
                    )}
                    {elementType == 'internal report' && (
                        <ReportViewer
                            {...viewerProps}
                            viewerRequestExtraData={viewerExtraData as ReportViewerDataType}
                        />
                    )}
                    {elementType == 'other external content' && (
                        <ExternalContentViewer
                            {...viewerProps}
                            viewerRequestExtraData={viewerExtraData as ExternalContentViewerDataType}
                        />
                    )}
                </>
            )}
        </Stack>
    );
}

export function usePrepareViewerData(
    userChartOverlay: number,
    setUserChartOverlay: any,
    setFilters: any,
    elementDisplayType: ElementDisplayContextType,
    viewerRequestData?: ElementViewerDataResponse
) {
    const urlParams: UrlParams = useParams();

    const [elementType, setElementType] = useState<DashboardElementType>();

    const sv = Number(urlParams.segmentValueId);
    const [segmentValueId, setSegmentValueId] = useState<number>(isNaN(sv) ? 0 : sv);

    const [viewerData, setViewerData] = useState<ElementViewerDataType>();
    const [viewerExtraData, setViewerExtraData] = useState<AnyElementsViewerDataType>();

    useEffect(() => {
        if (!viewerRequestData) {
            return;
        }

        const type = viewerRequestData.elementData.row.type;
        setElementType(type);

        setViewerData({
            elementData: viewerRequestData.elementData,
            segmentData: viewerRequestData.segmentData,
            segmentValueId: viewerRequestData.segmentValueId,
            targets: viewerRequestData?.targets,
            alertStatus: viewerRequestData.alertStatus,
        });

        let extraData: AnyElementsViewerDataType;
        let extra;
        switch (type) {
            case 'metric':
            case 'multi-metric chart':
                extra = viewerRequestData as ElementViewerDataType & MetricViewerDataType;
                extraData = {
                    chartData: extra.chartData,
                    manualCalendars: extra.manualCalendars,
                    metrics: extra.metrics,
                    userChartOverlay: userChartOverlay,
                    measIntervals: extra.measIntervals,
                };
                break;
            case 'external report':
                extra = viewerRequestData as ElementViewerDataType & ExternalReportViewerDataType;
                extraData = {
                    additionalData: extra.additionalData,
                    filtersData: extra.filtersData,
                    tabsData: extra.tabsData,
                    tableauCrossSite: extra.tableauCrossSite,
                };
                break;
            case 'internal report':
                extra = viewerRequestData as ElementViewerDataType & ReportViewerDataType;
                extraData = {
                    rendererFilters: extra.rendererFilters,
                    rendererBlocks: extra.rendererBlocks,
                    datasetFields: extra.datasetFields,
                    displayMasks: extra.displayMasks,
                };
                break;
            case 'other external content':
                extra = viewerRequestData as ElementViewerDataType & ExternalContentViewerDataType;
                extraData = {
                    additionalData: extra.additionalData,
                    fileCollection: extra.fileCollection,
                    fileId: extra.fileId,
                    urlsData: extra.urlsData,
                };

                break;
        }

        setViewerExtraData(extraData);
        if (elementDisplayType != 'screenshot') {
            // @ts-ignore
            const filters = viewerRequestData?.filtersData?.data?.filtersList;
            const { filtersList } = prepareSegments(filters ?? [], viewerRequestData.segmentData);
            setFilters(useApplyFilterValueMap(filtersList.slice()));
            if (elementDisplayType == 'viewer' && viewerRequestData.segmentValueId != segmentValueId) {
                setSegmentValueId(viewerRequestData.segmentValueId);
                const newURL = buildElementURLOnFilters(
                    viewerRequestData.elementData,
                    viewerRequestData.segmentValueId,
                    userChartOverlay
                );
                window.history.replaceState(null, document.title, newURL);
            }
        }
    }, [viewerRequestData]);

    return { elementType, viewerData, setViewerData, segmentValueId, setSegmentValueId, viewerExtraData };
}
