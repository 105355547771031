import { AssocArray, DashboardElementType, YNValues } from 'tools/types';
import { ExternalFilterParentMap, FilterType } from 'components/external-reference/Filter';
import { MetricViewerDataType } from 'components/metric/MetricViewer';
import { ExternalReportViewerDataType } from 'app/extreport/ExternalReportViewer';
import { DownloadFormatType } from 'components/common/DownloadLink';
import { ElementTopicsDataType } from 'components/element-viewer/element-topics';
import { FormComponentValue } from 'components/common/form/layout/control';
import { ReportViewerDataType } from 'app/report/ReportViewer';
import { ExternalContentViewerDataType } from 'app/ext-content/ExternalContentViewer';
import { ElementEmbeddingType } from 'components/element-viewer/IFrameElementViewer';

export interface ElementPermissions {
    edit: boolean;
}

export interface ElementRowData {
    elementId: number;
    segmentId: number;
    name: string;
    elementDashboardName: string;
    boEmail: string;
    businessOwner: string;

    dsEmail: string;
    dataSteward?: string;
    dataFetchMethod: string;

    toEmail: string;
    techOwner: string;

    description: string;
    descriptionMarkdown: string;
    descriptionMarkdownInd: boolean;
    externalContentDisplayInViewer: 'iframe' | 'image' | 'pdf';
    externalReportFullScreenMenuOnTop: string;
    externalReportFullScreenMouseStationaryInd: boolean;
    showMetricSummaryInfoInd: boolean;
    metricChartDisplayType: 'line' | 'bar' | 'column';
    metricChartLineStyle: 'jugged' | 'smooth';
    metricMinValueToChart?: string | null;
    metricMaxValueToChart?: string | null;
    metricYAxisLabel: string;
    metricStartYAxisFromZeroInd: boolean;
    metricShowMinEverOnChartInd: boolean;
    metricShowMaxEverOnChartInd: boolean;
    metricChangeBarColorOnValueInd: boolean;
    metricDisplayValueForPoint: YNValues;
    type: DashboardElementType;
    lastMeasurementTimeFormatted: string;
    lastDisplayGenerationTime: string;
    externalReportAutoUpdateImageInd: boolean;
    externalReportReferenceName: string;
    externalReportDisplay: string;
    externalReportUrl: string;
    calcIframeHeightOnImageAspectRatioInd: boolean;
    externalReportIframeHeight: number;
    showCollaborationInd: boolean;
    reportGenerateEmptyInstanceMessage: string;
    reportNoDataDuetoFiltersMessage: string;
}

export interface ElementInfoData {
    businessOwnerDn: string;
    businessOwnerEmail: string;
    technicalOwnerDn: string;
    technicalOwnerEmail: string;
    dataStewardDn: string;
    dataStewardEmail: string;
    categoryName: string;
    categoryId: number;
    engagement: number;
    certified: boolean;
    certifiedByDn: string;
    certifiedDate: string;
    customFields: Array<ElementViewerCustomFieldSection>;
}

export interface ElementCertification {
    enabled: boolean;
    name: string;
    color: string;
    lastUpdatedBy: string;
    lastUpdatedTime: string;
}

export interface ElementDownloadLink {
    key: string;
    icon: string;
    label: string;
    url: string;
}

export interface ElementDocumentType {
    createdBy: string;
    createdByUserId: number;
    createdTime: string;
    documentElementCountersId: number;
    documentElementId: number;
    documentId: number;
    documentType: string;
    documentTypeId: number;
    elementCount: number;
    externalUrl: string;
    fileName: string;
    isEditInd: boolean;
    isGlobalDocumentInd: boolean;
    isNewInd: boolean;
    isUpdatedInd: boolean;
    lastRevisionDate: string;
    name: string;
    shareWithAdminInd: boolean;
    shareWithPowerInd: boolean;
    shareWithRegularInd: boolean;
    sourceType: string;
    updatedTime: string;
}

export interface ElementRelatedElement {
    elementId: number;
    segmentId: number;
    elementType: DashboardElementType;
    elementName: string;
    elementDesc: string;
    // [external_report_type_id] =>
    // [external_report_url] =>
    // [external_report_display] =>
    // [sv_id] => 0
    // [instance_id] =>
    // [shared_measure_id] =>
    // [report_url_external_page_append] =>
    // [full_name] =>
}

export interface ElementDrillDownElement {
    drillId: number;
    drillToElementId: number;
    externalReportDisplay: string;
    externalReportTypeId: number;
    externalReportUrl: string;
    label: string;
    metricId: number;
    reportId: number;
    reportUrlExternalPageAppend: string;
    segmentId: number;
    segmentValueId: number;
    type: DashboardElementType;
}

export interface ElementInFavoritesType {
    favoriteId: number;
    displayName: string;
    displayOrder: number;
    selected: boolean;
}

export interface ElementType {
    row: ElementRowData;
    info: ElementInfoData;
    permissions: ElementPermissions;
    certification: ElementCertification;
    download: { links: Array<ElementDownloadLink>; formats: Array<DownloadFormatType> };
    documents: Array<ElementDocumentType>;
    customFields: Array<ElementViewerCustomFieldSection>;
    engagement: number;
    topics: ElementTopicsDataType;
    related: {
        related: Array<ElementRelatedElement>;
        includedMetrics: Array<any>;
        drillDown: Array<ElementDrillDownElement>;
    };
    inFavorites: Array<ElementInFavoritesType>;
    displayFullImage: boolean;
    availablePreview: boolean;
    noToken?: boolean;
}

export interface ElementViewerDataType {
    segmentData: Array<any>;
    elementData: ElementType;
    segmentValueId: number;
    targets: ElementTarget[];
    alertStatus: ElementAlertStatus;
}

export interface ElementTarget {
    target_id: number;
    name: string;
}

export interface ElementAlertStatus {
    alert_sms_email_enabled_ind: string;
    browser_count: number;
    digest_count: number;
    disabled: number;
    element_type: string;
    email_notification_enabled_ind: string;
    enabled: string;
    folder_set_ind: string;
    immediate_alerting_enabled_ind: string;
    immediate_count: number;
    send: string;
    send_alerts_email_ind: string;
    showIcon: string;
    sms_count: number;
    dataset_report: string;
}

export interface ElementViewerPropsType<T> {
    embeddingType?: ElementEmbeddingType;
    elementInfo: ElementType;
    targets?: ElementTarget[];
    alertStatus?: ElementAlertStatus;
    viewerRequestData: ElementViewerDataType; // Same data for all element types
    viewerRequestExtraData: T; // Unique for each element type
    onSegmentValueChange: (segmentValueId: number) => void;
    segmentValueId: number;
    filters: Array<FilterType>;
    related: Array<FormComponentValue>;
    onFiltersChange: (newFilters: Array<FilterType>) => void;
    onFavoritesChange: () => void;
    onRelatedElementSelection: (value: string) => void;
    onFilterChange: (filterId: number, newValue: any) => void;
    refetch: () => void;
    urlWidthValue?: number;
    urlHeightValue?: number;
    setUserChartInterval?: (newUCO: number) => void; // Only for Metric
}

export type ElementViewerCustomFieldType = 'single' | 'multi' | 'textarea' | 'date' | 'users' | 'email';

export interface ElementViewerCustomFieldSection {
    cfs_id: number;
    label: string;
    fields: Array<ElementViewerCustomField>;
}

export interface ElementViewerCustomField {
    id: number;
    name: string;
    supportRestriction: boolean;
    type: ElementViewerCustomFieldType;
    values: Array<string> | AssocArray<string>;
    link?: string;
}

export type AnyElementsViewerDataType =
    | MetricViewerDataType
    | ExternalReportViewerDataType
    | ReportViewerDataType
    | ExternalContentViewerDataType;

export type ElementViewerDataResponse = ElementViewerDataType & AnyElementsViewerDataType;

export interface ElementInfoType {
    element_id: number;
    segment_value_id: number;
    name: string;
    type: string;
}

export interface DocumentEntityType {
    document_id: number;
    // "document_type_id": 1,
    name: string;
    // "source_type": "file",
    // "external_url": "",
    // "file_name": "Getting started.pdf",
    // "created_time": "2019-06-21 11:13:44",
    // "updated_time": "2021-03-24 11:04:39",
    // "created_by_user_id": 32,
    // "last_revision_date": "2019-06-21 11:13:44",
    // "is_global_document_ind": "Y",
    // "share_with_admin_ind": "Y",
    // "share_with_power_ind": "Y",
    // "share_with_regular_ind": "Y"
}

export function prepareSegments(
    filtersList: Array<FilterType>,
    segmentData: Array<any>
): {
    filtersList: Array<FilterType>;
    segmentValuesMap: any;
} {
    // Add Segment Values As Filters
    const segmentValuesMap = {};
    segmentData = segmentData.map((el: any) => {
        el.isSegment = true;
        // This is SegmentId, to avoid collision with real FilterId multiply by -1;
        el.filterId = Math.abs(el.filterId) * -1;
        el.parentFilterId = Math.abs(el.parentFilterId) * -1;
        el.required = true;
        // @ts-ignore
        segmentValuesMap[el.filterId] = el.values;
        return el;
    });
    const result = structuredClone(filtersList).concat(segmentData);

    result.forEach((filter: FilterType) => {
        if (filter.filterInput == 'single' && !filter.required) {
            filter.values.unshift({
                value_id: '',
                value: 'All Values',
                display_value: 'All Values',
                isVisible: true,
            });
        }
        // Set default state before Bookmark Apply
        filter.defaultValues = filter.selectedValues.slice();
        if (filter.isSegment) {
            filter.visibleInViewer = true;
        }
        // Set parents map
        filter.parentsMap = new ExternalFilterParentMap(filter.filterId);
        filter.values.forEach((v) => {
            v.isVisible = true;
            if (v.map) {
                filter.parentsMap.addMap(v.value_id, v.map);
            }
        });
    });
    return {
        filtersList: result,
        segmentValuesMap: segmentValuesMap,
    };
}

export function buildElementURLOnFilters(
    element: ElementType,
    segmentValueId: number,
    uco?: number,
    viewMode?: string
): string {
    const id = element.row.elementId;
    const type = element.row.type == 'metric' || element.row.type == 'multi-metric chart' ? 'chart' : 'extreport';

    let result = `/${type}/${id}`;
    if (segmentValueId) {
        result += `/segment/${segmentValueId}`;
    }
    if (uco) {
        result += `/uco/${uco}`;
    }

    if (viewMode) {
        result += `/view/${viewMode}`;
    }

    return result;
}
