import React, { useEffect, useState } from 'react';
import useBundleTranslation from 'i18n';
import { alpha, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Popup } from 'components/common/popup/Popup';
import { distributionListAPI } from 'api/api';
import { useNavigate } from 'react-router-dom';
import FormStaticInfo from 'components/common/form/layout/static/FormStaticInfo';
import { FormRendererAPIType, RawFormComponentType } from 'components/common/form';
import { useForm } from 'react-hook-form';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { prepareFormElementProps } from 'components/common/form/formTools';

interface burstOption {
    notification_schedule_distribution_id?: number | string;
    name: string;
    ns_internal_name: string;
    dl_enabled?: string;
}

interface popupMainData {
    element: {
        element_id: number | string;
        element_dashboard_name: string;
    };
    bursts: burstOption[];
}

export interface IncludeInBurstPopupProps {
    onClose: (event: any) => void;
    elementId: number;
    segmentId: number;
}

//AlertRuleList2.openAddToBurstPopup
//engine/application/notification/views/scripts/distributionlist/include-in-burst-popup.phtml
export default function IncludeInBurstPopup(props: IncludeInBurstPopupProps) {
    const { onClose = () => {}, elementId, segmentId = 0 } = props;

    const { t } = useBundleTranslation(['components/distribution-list/include_in_burst_popup']);
    const [mainData, setMainData] = useState<popupMainData>();
    const [selectedBurst, setSelectedBurst] = useState<string>('0');
    const [createStatus, setCreateStatus] = useState<string>();
    const [createMessage, setCreateMessage] = useState<string>();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    //action POST: notification/distributionlist/tile-new-burst
    useEffect(() => {
        distributionListAPI
            .includeInBurstData(elementId, segmentId)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    setMainData(data);
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (mainData && !mainData.bursts.length) onConfirm();
    }, [mainData]);

    const { setValue: hookFormSetValue, control, getValues: hookFromGetValues, watch } = useForm({});
    const formActions = {
        hookFormControl: control,
        hookFormGetValues: hookFromGetValues,
        hookFormSetValue: hookFormSetValue,
        hookFormWatch: watch,
        formDidMount: true,
    } as FormRendererAPIType;

    if (!mainData) return null;

    const onConfirm = () => {
        setCreateStatus('');
        setShowLoading(true); //text "Creating..."
        distributionListAPI
            .includeInBurstCreate(elementId, segmentId, selectedBurst)
            .then((response) => {
                setShowLoading(false);
                if (response.data?.status === 'OK') {
                    setShowLoading(true); //text "Redirecting to editor..."
                    if (response.data?.id) {
                        navigate(`/notification/burst/${response.data?.id}/`);
                    } else if (response.data?.redirectUrl) {
                        navigate(response.data?.redirectUrl);
                    }
                } else if (response.data?.status === 'ERROR') {
                    setCreateMessage(response.data?.message ?? t('error_msg'));
                    setCreateStatus('error');
                }
            })
            .catch(() => {});
    };

    //Custom loading mask
    const loadingMask = (
        <LoadingPlaceholder
            sx={{
                position: 'absolute',
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.5),
                color: (theme) => alpha(theme.palette.text.primary, 0.4),
            }}
        />
    );

    return (
        <Popup
            settings={{
                title: `Burst "${mainData.element.element_dashboard_name}"`,
                textOK: t('apply_btn'),
                noCancel: true,
            }}
            maxWidth="popupSm"
            open={true}
            onHide={onClose}
            onConfirm={onConfirm}
        >
            {showLoading && loadingMask}
            <FormControl sx={{ width: 1 }}>
                <RadioGroup
                    value={selectedBurst}
                    name="burst"
                    sx={{ width: 1 }}
                    onChange={(event) => {
                        setSelectedBurst(event.target.value);
                    }}
                >
                    <FormControlLabel value="0" control={<Radio />} label={t('create_new_label')} />
                    {mainData.bursts.length > 0 && (
                        <>
                            <FormLabel sx={{ mt: 2 }}>{t('exist_label')}</FormLabel>
                            <Stack
                                sx={{
                                    maxHeight: 300,
                                    overflow: 'auto',
                                    width: '100%',
                                    border: '1px solid',
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    px: 1,
                                }}
                            >
                                {mainData.bursts.map((item) => {
                                    const value = String(
                                        item.notification_schedule_distribution_id ?? item.ns_internal_name
                                    );
                                    const label = `${item.name}${
                                        item.dl_enabled == 'N' ? ' ' + t('option_disabled_suffix') : ''
                                    }`;
                                    return (
                                        <FormControlLabel
                                            value={value}
                                            control={<Radio />}
                                            label={label}
                                            key={item.notification_schedule_distribution_id}
                                        />
                                    );
                                })}
                            </Stack>
                        </>
                    )}
                </RadioGroup>
            </FormControl>
            {createStatus && (
                <Box sx={{ mt: 2 }}>
                    <FormStaticInfo
                        elementProps={prepareFormElementProps({
                            form: formActions,
                            component: {
                                label: createMessage,
                                props: {
                                    type: createStatus,
                                },
                            } as RawFormComponentType,
                        })}
                    />
                </Box>
            )}
        </Popup>
    );
}
