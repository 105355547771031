import { useContext } from 'react';
import { DownloadManagerContext, DownloadManagerHandlerProps } from 'services/DownloadManager';
import { Stack } from '@mui/material';

export type DownloadFormatType = 'csv' | 'csvf' | 'xlsx' | 'pdf' | 'pptx';
export function DownloadLink({
    children,
    downloadOptions,
    options,
}: {
    children: any;
    downloadOptions: DownloadManagerHandlerProps;
    options?: any;
}) {
    const downloadHandler = useContext(DownloadManagerContext);
    const callDownloadManager = function () {
        downloadHandler(downloadOptions, options);
    };

    return (
        <Stack direction={'row'} alignItems={'center'} onClick={callDownloadManager}>
            {children}
        </Stack>
    );
}

export function DownloadLinkElement({
    children,
    elementId,
    segmentValueId,
    format,
    uco,
    filters,
}: {
    children: any;
    elementId: number;
    segmentValueId: number;
    format: string;
    uco?: number;
    filters?: string;
}) {
    const props = {
        elementId: elementId,
        segmentId: segmentValueId,
        format: format,
    };

    let options: any = {};
    if (uco || filters) {
        if (uco) {
            options.uco = uco;
        }
        if (filters) {
            options.filters = filters;
        }
    } else {
        options = undefined;
    }

    return (
        <DownloadLink options={options} downloadOptions={props}>
            {children}
        </DownloadLink>
    );
}
