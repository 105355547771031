import { AssocArray } from 'tools/types';
import React from 'react';
import ExampleForm from 'form-templates/ExampleForm';
import { IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import BurstForm from 'app/notification/burst/form-template/BurstForm';
import BrandThemeForm from 'app/editor/brand-theme/form-template/BrandThemeForm';
import BrandForm from 'app/editor/brand/form-template/BrandForm';
import ReportForm from 'form-templates/ReportForm';
import ManageOverlayForm from 'form-templates/ManageOverlayForm';
import GlossarySectionUsage from 'form-templates/GlossarySectionUsage';
import ExtContentReportMass from 'form-templates/ExtContentReportMass';
import ExtContent from 'form-templates/ExtContent';
import SegmentValueForm from 'form-templates/SegmentValueForm';
import SegmentForm from 'form-templates/SegmentForm';
import CertificationLevelSyncForm from 'form-templates/CertificationLevelSyncForm';
import DashboardPreferenceForm from 'form-templates/DashboardPreferenceForm';
import DatasetForm from 'form-templates/DatasetForm';
import ElementShareForm from 'form-templates/ElementShareForm';
import ExportForm from 'form-templates/ExportForm';
import CompoundValueForm from 'form-templates/CompoundValueForm';
import MetricForm from 'form-templates/MetricForm';
import PortalPageTemplateForm from 'form-templates/PortalPageTemplateForm';
import PortalPageForm from 'form-templates/PortalPageForm';
import PortalPageAssetForm from 'form-templates/PortalPageAssetForm';
import ImportForm from 'form-templates/ImportForm';
import TargetDataImportForm from 'form-templates/TargetDataImportForm';

export const CustomFormTemplates: AssocArray<React.FC<IFormLayoutProps>> = {
    ExampleForm: ExampleForm,
    BurstForm: BurstForm,
    BrandForm: BrandForm,
    BrandThemeForm: BrandThemeForm,
    ReportForm: ReportForm,
    ManageOverlayForm: ManageOverlayForm,
    GlossarySectionUsage: GlossarySectionUsage,
    ExtContentReportMass: ExtContentReportMass,
    ExtContent: ExtContent,
    SegmentValueForm: SegmentValueForm,
    SegmentForm: SegmentForm,
    CertificationLevelSyncForm: CertificationLevelSyncForm,
    DashboardPreferenceForm: DashboardPreferenceForm,
    DatasetForm: DatasetForm,
    ElementShareForm: ElementShareForm,
    ExportForm: ExportForm,
    CompoundValueForm: CompoundValueForm,
    MetricForm: MetricForm,
    PortalPageTemplateForm: PortalPageTemplateForm,
    PortalPageForm: PortalPageForm,
    PortalPageAssetForm: PortalPageAssetForm,
    ImportForm: ImportForm,
    TargetDataImportForm: TargetDataImportForm,
};
